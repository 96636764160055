<template>
    <div>
        <label>Products you might like ?</label>

        <div v-for="product in addonProducts" :key="product.slug"
             style="border-radius: 5%; background-color: #3e3d3d; padding: 20px">
            <label>WIDGET</label>
            <span>{{ product.name }}</span><br/>
            <span>${{ calculatePriceWithVat(product.price / 100)
                }} / </span>
            <span style="color: rgba(255, 255, 255, 0.6)"> {{ product.planDetails.addonProducts[0].subscription_length
                }} {{ product.planDetails.addonProducts[0].subscription_length_type }}</span>
            <br/>
            <br/>
            <label style="font-size: small" v-html="product.description"></label>
            <br/>
            <base-button style="margin-right: 5px" mode="white" :disabled="selected"
                         @click.native="openInstructions(product.planDetails.addonProducts[0])">Learn more
            </base-button>
            <base-button style="margin-right: 5px" v-if="product.selected === true" disabled>Selected</base-button>
            <base-button v-else @click.native="addProduct(product)" mode="green">Add to order</base-button>
        </div>
        <br/>
        <div v-for="product in promoProducts" :key="product.slug"
             style="border-radius: 5%; background-color: #3e3d3d; padding: 20px; margin-bottom: 20px">
            <span>{{ product.name }}</span> <br/>
            <span>${{ calculatePriceWithVat(product.price / 100) }} / </span> <span style="color: rgba(255, 255, 255, 0.6)"> {{ product.subscription_length
                }} {{ product.subscription_length_type }}</span><br/><br/>
            <label style="font-size: small">One off charge. {{ product.subscription_length }} {{ product.subscription_length_type }} access</label><br/>

            <base-button style="margin-right: 5px" mode="white" :disabled="selected"
                         @click.native="openInstructions(product)">Learn more
            </base-button>
            <base-button style="margin-right: 5px" v-if="product.selected === true" disabled>Selected</base-button>
            <base-button v-else @click.native="addProduct(product)" mode="green">Add to order</base-button>

        </div>
    </div>
</template>

<script>
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import _ from "lodash";
import ItemInstructionModal from "./ItemInstructionModal";

export default {
    name: "ProductSuggestions",
    mixins: [SubscriptionMixin],
    props: {
        addHandler: Function
    },
    data: function () {
        return {
            products: []
        }
    },
    computed: {
        addonProducts: function () {
            return this.products.filter(product => product.product_type === "addon");
        },
        promoProducts: function () {
            return this.products.filter(product => product.product_type === "promotion");
        }
    },
    methods: {
        openInstructions: function (product) {
            console.log("rr");
            this.showModal(ItemInstructionModal, {item: product});
        },
        addProduct: function (product) {
            product.selected = true;
            this.addHandler(product);
        }
    },
    mounted() {
        let products = [];
        this.promotionalProducts
            .forEach(item => products.push({
                ...item,
                selected: false,
                product_type: "promotion",
                price: item.pricing["USD"]
            }));
        this.sortedWidgetsByCreationDate
            .filter(item => item.planDetails.included === false)
            .forEach(item => products.push({
                ...item,
                selected: false,
                product_type: "addon",
                price: item.planDetails.priceNumeric
            }));
        this.products = _.orderBy(products, "price", "desc").slice(0, 3);

    }
}
</script>

<style scoped>

</style>
